<template>
  <v-container fluid> </v-container>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  props: {},
  data: () => ({}),
};
</script>
